import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout/index"
import SEO from "../components/seo"
import Container from '../components/container'

export default () => {
  return (
    <Layout>
    <SEO title="404: Not found" />
    <Section>
      <Container
          sm={`
            padding-top:3rem;
            padding-bottom:3rem;
          `}
          lg={`
            padding-top:4.5rem;
            padding-bottom:4.5rem;
          `}
        >
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Container>
    </Section>
  </Layout>
  )
}

const Section = styled.section`
  @media (min-width:992px) {
    min-height:calc(100vh - 590px);
  }
`